import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import useWindowWidth from '../../hooks/useWindowHook'
import { spacing, colors } from '../../utils/styles'

class ExpandableCell extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpened: props.isOpened,
    }

    this.handleOpenChanged = this.handleOpenChanged.bind(this)
  }

  static get propTypes() {
    return {
      id: PropTypes.string.isRequired,
      isOpened: PropTypes.bool,
      question: PropTypes.string,
      answer: PropTypes.string,
      handleOpenExpandableCell: PropTypes.func,
      handleCloseExpandableCell: PropTypes.func,
    }
  }

  static get defaultProps() {
    return {
      isOpened: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpened !== this.state.isOpened) {
      this.setState({ isOpened: nextProps.isOpened })
    }
  }

  handleOpenChanged(e) {
    e.preventDefault()

    const { isOpened } = this.state
    this.setState({
      isOpened: !isOpened,
    })

    if (!isOpened == true && this.props.handleOpenExpandableCell) {
      this.props.handleOpenExpandableCell(this.props.id)
    } else if (!isOpened == false && this.props.handleCloseExpandableCell) {
      this.props.handleCloseExpandableCell(this.props.id)
    }
  }

  render() {
    const { isOpened } = this.state
    const { question, answer } = this.props

    return (
      <li
        className="container flex-parent flex-parent--column"
        onClick={this.handleOpenChanged}
      >
        <div
          className="flex-parent flex-parent--row flex-parent--center-cross"
          onClick={this.handleOpenChanged}
        >
          <h5 className="flex-child">{question}</h5>

          <div
            className={`closeButton flex-parent flex-parent--center-main${
              isOpened === false ? ' ' : ' open'
            }`}
          >
            <div className="flex-child lineHorizontal lineClose" />
            <div className="flex-child lineVertical lineClose" />
          </div>
        </div>
        {isOpened === true && <p className="flex-child paragraph">{answer}</p>}
        <style jsx>
          {`
            .container {
              height: auto;
              width: 100%;
              cursor: pointer;
            }
            .closeButton {
              position: relative;
              width: 16px;
              height: 16px;
              margin-left: auto;
              margin-right: 0;
              transition: all 0.2s ease-in-out;
              transform-origin: 50% 50%;
              transform-style: preserve-3D;
              margin-right: ${spacing.md}px;
            }
            .lineClose {
              width: 100%;
              height: 2px;
              background: ${colors.darkest};
            }
            .lineHorizontal {
              position: absolute;
              top: 50%;
              left: 0;
            }
            .lineVertical {
              position: absolute;
              top: 50%;
              left: 0;
              transform: rotate(90deg);
            }
            :global(.paragraph) {
              margin-top: ${spacing.md}px;
              max-width: 620px;
            }
            .open {
              transform: rotate(45deg);
            }
          `}
        </style>
      </li>
    )
  }
}

ExpandableCell.List = ({ children, className = '', ...props }) => (
  <ul className={classNames(className, 'expandableCellList')} {...props}>
    {children}
    <style jsx>
      {`
        .expandableCellList {
          display: flex;
          flex-direction: column;
          margin: 0;
          padding: 0;
          text-indent: 0;
          list-style-type: none;
          width: 100%;
        }
        :global(.expandableCellList > li) {
          padding-top: 24px;
          padding-bottom: 24px;
          border-top: 1px solid ${colors.ligherGrey};
        }
        :global(.expandableCellList:last-child) {
          border-bottom: 1px solid ${colors.ligherGrey};
        }
      `}
    </style>
  </ul>
)

export default ExpandableCell
