import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'

import ExpandableCell from '../components/shared/ExpandableCell'
import App from '../components/App'

import { spacing } from '../utils/styles'

class Template extends React.Component {
  render() {
    const data = get(this.props, 'data.contentfulHelpFaq')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <App style={{ background: '#fff' }}>
        <Helmet title={`${data.title} | ${siteTitle}`} />

        <div className="wrapper sectionContainer">
          <h3 className="faq">FAQ</h3>
          <div className="faqList flex-parent flex-parent--row flex-parent--wrap flex-parent--space-between-main">
            <ExpandableCell.List>
              <ExpandableCell
                id="1"
                isOpened={true}
                question="Cum pot sa schimb sau sa anulez comanda mea?"
                answer="Pentru a schimba sau anula comanda va rugam sa ne contactati pe email la hello@leaf.ro cat de repede posibil."
              />
            </ExpandableCell.List>
          </div>
        </div>

        <style jsx>{`
          .wrapper {
            padding-top: 120px;
          }
          .section-headline {
            margin-bottom: 40px;
          }
          .faqList {
            margin-top: ${spacing.lg}px;
          }
        `}</style>
      </App>
    )
  }
}

export default Template

export const pageQuery = graphql`
  query FaqBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        description
      }
    }
    contentfulHelpFaq(slug: { eq: $slug }) {
      title
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
